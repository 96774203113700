

.imagenesLogo {
  display: block;
  max-width: 190px;
  object-fit: cover;
  opacity: 1;
}
.imagenesContainer {
  object-fit: cover;
}
.imagenesLogo:hover {
  opacity: 0.7;
  max-width: 192px;
  cursor: pointer;
}
.link {
  text-decoration: none;
  color: #fff;
}
.link:hover {
  color: #1886a0;
}

@media (min-width: 600px) and (max-width: 800px) {
  .parrafo01 {
    font-size: large !important;
    padding-left: 5vw !important;
  }
  .parrafo02 {
    font-size: x-large !important;
    padding-left: 5vw !important;
    padding-right: 10vw !important;
  }
  .parrafo07 {
    font-size: large !important;
  }
  .sobreNosotros {
    padding-left: 0vw !important;
    padding-right: 0vw !important;
    overflow: hidden !important;
  }
  .imgPartnerContainer {
    min-width: 50vw !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-size: cover;
    max-width: 40% !important;
    overflow: hidden !important;
  }
  .cardMovil {
    max-width: 220px !important;
    min-height: 550px !important;
  }
  .parrafoCard01 {
    font-size: larger !important;
    font-weight: 600 !important;
    text-align: center;
  }
  .parrafoCard02 {
    padding-left: 1vw;
    padding-right: 1vh;
  }
}

@media (max-width: 576px) {
  .fondoSobreNosotros {
    overflow: hidden !important;
    min-height: 85vh !important;
  }
  .parrafo01 {
    overflow: hidden !important;
    padding: 35vh 0vh 2vh 5vh !important;
  }
  .parrafo02 {
    overflow: hidden !important;
    font-size: large !important;
    padding: 2vh 0vh 2vh 5vh !important;
  }
  .parrafo03 {
    overflow: hidden !important;
    color: #248da5;
    font-weight: 600;
    padding: 0vh 0vw 0vh 5vw !important;
  }
  .imgPartnerContainer {
    overflow: hidden !important;
    display: none;
  }
  .sobreNosotros {
    overflow: hidden !important;
    min-width: 99% !important;
    padding: 0vh !important;
  }
  .cardHover {
    overflow: hidden !important;
    align-items: center !important;
    margin-bottom: 2vh !important;
  }
  .containerPrefooter {
    overflow: hidden !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .acordionItem {
    overflow: hidden !important;
    max-width: 350px !important;
  }
  .imagenesContainer {
    padding-right: 10vw !important;
  }
}

@media (min-width: 1440px) {
  .sobreNosotros {
    margin-left: 1vh;
  }
  .imgPartnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
