.Mapa {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 110px;
  }
  
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .logoportalOC {
    width: 14%;
  }
  
  .lineSgr {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
  }
  
  .line-dynamics-sgr {
    display: flex;
    justify-content: center;
    position: relative;
  }
  
  .line-protector {
    display: flex;
    justify-self: end;
    align-items: end;
    padding-left: 49em;
  }
  
  .circle-container {
    display: flex;
    gap: 150px;
  }
  
  .circle-container.top {
    margin-bottom: 30px;
    position: absolute;
    gap: 350px;
  }
  
  .circle-container.bottom {
    gap: 350px;
  }
  
  h4 {
    font-weight: 600;
    color: #7A7C7F;
    padding-right: 10px;
  }
  
  @media (max-width: 768px) {
    .circle-container {
      gap: 100px;
    }
  
    .circle-container.top {
      margin-bottom: 20px;
      gap: 100px;
    }

    .line-protector{
      padding-left: 33em;
    }
  }
  
  @media (max-width: 576px) {
    .logoportalOC {
      width: 30%;
      margin-top: 12px;
    }
  
    .circle-container.bottom {
      gap: 20px;
    }

    .line-protector{
      padding-left: 13em;
    }
  }
  