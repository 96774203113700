
.link{
    text-decoration: none;
    color: #fff;
}
.link:hover{
    color: #1886A0;
}
.cardHover:hover{
    border-top: 1px solid #1786A0;
    border-left: 1px solid #1786A0;
}
@media (max-width: 800px) {
    .parrafo01{
        overflow: hidden !important;
        padding: 35vh 0vh 2vh 5vh !important;
    }
    .parrafo02{
        overflow: hidden !important;
        font-size: large !important;
        padding: 2vh 0vh 2vh 5vh !important;
    }
    .contactoContainer{
        overflow: hidden !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items:flex-start !important;
        padding-left: 1vw !important;
        margin: 0px !important;
    }
    .contactoIframe{
        overflow: hidden !important;
        min-width: 99vw !important;
        padding: 2vh 0px 0px !important;
        margin: 0px !important;
    }
    .iframe{
        overflow: hidden !important;
    }
    .contactoFormulario{
        overflow: hidden !important;
        min-width: 100vw !important;
        padding: 2vh 0px 0px !important;
        margin: 0px !important;
    }
    .contactoCard{
        margin-top: 5vh !important;
    }
}