.circle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.forma {
  background-color: #dddddd00;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
  position: relative;
}

.circulo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.rectangulo {
  width: 150px;
  height: 80px;
  border-radius: 10px;
}

.forma.hover {
  transform: scale(1.2);
}

.forma-name {
  margin-bottom: 5px;
  text-align: center;
  color: #7A7C7F;
  transition: color 0.3s;
  font-weight: 600;
}

.forma-name.hover {
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .circulo {
    width: 50px; 
    height: 50px;
  }

  .rectangulo {
    width: 120px;
    height: 60px;
  }
}

@media (max-width: 576px) {
  .circulo {
    width: 40px; 
    height: 40px;
  }

  .rectangulo {
    width: 100px; 
    height: 50px;
  }
  .forma-name {
    font-size: 0.8rem;
  }
}
