

@media (min-width: 600px) and (max-width: 800px){


  .mapaContacto {
    overflow: hidden !important;
  }
}

@media (max-width: 576px) {
  .displayNone {
    display: none;
  }
  .parrafo01Movil {
    overflow: hidden !important;
    padding: 7vw 10vw !important;
    font-size: large !important;
  }
  .parrafo02Movil {
    overflow: hidden !important;
    padding: 0 15vw;
    font-size: medium !important;
  }
  .cardContainer1 {
    overflow: hidden !important;
    margin-top: 3vh;
    margin-bottom: 2vh !important;
  }

  .suiteHRMovil {
    display: flex;
    flex-direction: column !important;
  }
  .gridImgMovil {
    overflow: hidden !important;
    padding: 0px 0vw 0px 0px !important;
    margin: 0px 15vw 0px 0px !important;
  }
  .imgHRFactors {
    overflow: hidden !important;
    display: none;
  }
  .parrafo04Movil {
    overflow: hidden !important;
    font-size: x-large !important;
    padding-top: 5vh !important;
    padding-bottom: 4vh;
  }
  .parrafo05Movil {
    font-weight: 500 !important;
    font-size: medium !important;
    overflow: hidden !important;
    padding-right: 2vw !important;
    padding-left: 9vw !important;
  }
  .suiteHRMovil02 {
    overflow: hidden !important;
    margin-top: 0vh;
  }
  .fondoDecisiones {
    overflow: hidden !important;
    margin-top: 2vh !important;
  }
  .serviciosMovil {
    overflow: hidden !important;
    font-size: x-large;
    text-align: center;
    padding: 5vh;
  }
  .borderBottomMovil {
    overflow: hidden !important;
    border-bottom: 2px solid white;
  }
  .paddinBottomMovil {
    overflow: hidden !important;
    margin-bottom: 5vh;
  }
  .gridMovil {
    overflow: hidden !important;
    padding: 0px !important;
    min-width: 95vw !important;
  }
  .accordionContainer {
    overflow: hidden !important;
    min-width: 100vw !important;
  }
  .parrafo05Movil {
    overflow: hidden !important;
    font-weight: 600;
  }
  .fondoConfianNosotros {
    overflow: hidden !important;
    padding: 0px;
  }
  .parrafo05Movil {
    overflow: hidden !important;
    font-size: large;
  }
  .mapaContacto {
    overflow: hidden !important;
  }
  .botonFlotante {
    display: none !important;
  }
}

@media (min-width: 1440px) {
  .cardContainer {
    justify-content: space-evenly !important ;
  }
  .cardContainer1 {
    justify-content: space-evenly !important;
  }
  .accordionContainer {
    padding-right: 5vh;
  }
}
